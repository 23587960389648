@font-face {
  font-family: "Space";
  src: url("../public/SpaceGrotesk[wght].woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
}

ul,
a {
  text-decoration: none;
  color: inherit;
  list-style: none;
}

:root {
  --clr-dark: #191a23;
  --clr-gray: #f3f3f3;
  --clr-accent: #715fb2;

  --fs-400: 1.25rem;
  --fs-500: 2.2rem;
  --fs-600: 3.2rem;
  --pd-x: clamp(1rem, -1.059rem + 7.843vw, 6rem);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  font-family: Space, sans-serif;
  overflow-x: hidden;
}

@media only screen and (max-width: 1430px) {
  :root {
    --fs-600: 3rem;
  }
}

@media only screen and (max-width: 1060px) {
  :root {
    --fs-600: 2.4rem;
  }
}

@media only screen and (max-width: 864px) {
  :root {
    --fs-600: 2.2rem;
  }

  @media only screen and (max-width: 765px) {
    :root {
      --fs-600: 2rem;
    }
  }

  @media only screen and (max-width: 692px) {
    :root {
      --fs-500: 1.8rem;
      --fs-600: 2rem;
    }
  }
}
